<template>
  <div class="d-flex">
    <div
      ref="sidebar"
      class="sidebar fixed-top d-block mt-5"
      style="
        overflow-x: hidden;
        z-index: 1;
        width: 250px;
        min-width: 250px;
        z-index: 111;
      "
    >
      <Sidebar v-once :sideBarList="GetSideBarList" />

      <div class="toggle-btn" ref="toggleBtn" @click="toggleSideBar()">
        <div class=""><b-icon icon="chevron-double-right"></b-icon></div>
      </div>
    </div>
    <div
      class="content-view px-4 mt-5 pt-4"
      style="
        background: #f6f6f7;
        min-height: 92vh;
        width: calc(100% - 250px);
        margin-left: 250px;
      "
      @click="hideToggleButton()"
    >
      <AlertBadge
        v-if="
          AlertObj &&
          AlertObj?.status &&
          AlertObj.message
        "
        :text="AlertObj?.message"
        :variant="AlertObj?.variant"
      ></AlertBadge>
      <!-- </div> -->
      <AlertBadge
        :text="`Email verification pending! Please verify your email.`"
        :isEmailVerificationPending="true"
        :variant="`quizell-warning-alert`"
        v-if="isEmailNotVerified"
      ></AlertBadge>
      <router-view></router-view>

      <!-- Upgrade Plan Modal Start -->
      <div
        class="modal fade"
        id="UpgradePlanModalNewLayout"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        v-bind="$attrs"
        ref="UpgradePlanModalNewLayout"
      >
        <div class="modal-dialog modal-lg">
          <div
            class="modal-content"
            id="UpgradePlanContentModalNewLayout"
            style="border-radius: 20px"
          >
            <!-- Modal Content -->
            <div>
              <div class="">
                <div class="container px-4">
                  <div
                    class="row px-3 py-3 d-flex justify-content-end align-items-center"
                  >
                    <div>
                      <button
                        @click="CloseModalUpgrade"
                        class="btn outline-none outline-none border-0"
                        style="cursor: pointer; color: #323232"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>

                  <div class="row pb-5">
                    <div
                      class="col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center"
                    >
                      <LazyImage
                        :src="'https://quizell.nyc3.cdn.digitaloceanspaces.com/website/upgradePlanBanner.png'"
                        style="height: 250px; width: 250px"
                        alt=""
                      />
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                      <div class="row" style="text-align: start !important">
                        <div class="col-12">
                          <p
                            style="
                              color: #292929;
                              font-weight: 600;
                              font-size: 22px;
                            "
                          >
                            {{ upgradeModalTitle }}
                          </p>
                        </div>
                        <div class="col-12">
                          <p
                            style="
                              color: #4d4950;
                              font-weight: 400;
                              font-size: 16px;
                            "
                            v-html="upgradeModalsubText"
                          ></p>
                        </div>
                        <div class="col-12 mt-2">
                          <button
                            @click="openUpgradePlan()"
                            class="px-3 py-2 outline-none outline-none border-0"
                            style="
                              background: #ffa201;
                              color: #ffffff;
                              border-radius: 6px;
                            "
                          >
                            Upgrade Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBadge from "./AlertBadge.vue";
// import accountVue from "./account>.vue";
import { mapGetters, mapMutations ,mapActions} from "vuex";
import Sidebar from "./Sidebar.vue";
import $ from "jquery";
export default {
  components: {
    Sidebar,
    AlertBadge,
  },
  data() {
    return {
      toggle: false,
      sideBarList: [
        {
          route: "ManageQuiz",
          activeLinks: ["CreateQuiz"],
          name: "Quizzes",
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_35_2729)">
<path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z" />
</g>
<defs>
<clipPath id="clip0_35_2729">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`,
          permissions: "",
          showUser: ["all"],
        },

        {
          route: "Analytics",
          activeLinks: [],
          name: "Analytics 2.0",
          icon: `<i class="fa-solid fa-chart-line" style='font-size:21px'></i>
        `,
          permissions: "",
          showUser: ["all"],
        },

        {
          route: "Product",
          activeLinks: [
            "AddProduct",
            "EditProduct",
            "Product",
            "Vendor",
            "Collection",
            "Tag",
            "BulletDescription",
          ],
          name: "Products",
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_414_383)">
<path d="M18 6H16C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6H6C4.9 6 4 6.9 4 8V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8C20 6.9 19.1 6 18 6ZM10 10C10 10.55 9.55 11 9 11C8.45 11 8 10.55 8 10V8H10V10ZM12 4C13.1 4 14 4.9 14 6H10C10 4.9 10.9 4 12 4ZM16 10C16 10.55 15.55 11 15 11C14.45 11 14 10.55 14 10V8H16V10Z" />
</g>
<defs>
<clipPath id="clip0_414_383">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

`,
          children: [
            {
              route: "Product",
              name: "Products",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_414_383)">
<path d="M18 6H16C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6H6C4.9 6 4 6.9 4 8V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8C20 6.9 19.1 6 18 6ZM10 10C10 10.55 9.55 11 9 11C8.45 11 8 10.55 8 10V8H10V10ZM12 4C13.1 4 14 4.9 14 6H10C10 4.9 10.9 4 12 4ZM16 10C16 10.55 15.55 11 15 11C14.45 11 14 10.55 14 10V8H16V10Z" />
</g>
<defs>
<clipPath id="clip0_414_383">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
            {
              route: "Collection",
              name: "Collections",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_50_37953)">
<path d="M13.1256 18.1356C13.3891 18.0034 13.5556 17.733 13.5556 17.4372V13.2368C13.5556 13.0296 13.6375 12.831 13.7834 12.6846L18.5442 7.90455C18.8359 7.6117 19 7.21422 19 6.79956V4.78091C19 4.34907 18.6523 4 18.2222 4H5.77778C5.34767 4 5 4.34907 5 4.78091V6.79956C5 7.21422 5.16411 7.6117 5.45578 7.90455L10.2166 12.6846C10.3625 12.831 10.4444 13.0296 10.4444 13.2368V18.2181C10.4444 18.7986 11.0529 19.1762 11.5701 18.9165L13.1256 18.1356Z"/>
</g>
<defs>
<clipPath id="clip0_50_37953">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
            {
              route: "Tag",
              name: "Tags",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_50_37953)">
<path d="M13.1256 18.1356C13.3891 18.0034 13.5556 17.733 13.5556 17.4372V13.2368C13.5556 13.0296 13.6375 12.831 13.7834 12.6846L18.5442 7.90455C18.8359 7.6117 19 7.21422 19 6.79956V4.78091C19 4.34907 18.6523 4 18.2222 4H5.77778C5.34767 4 5 4.34907 5 4.78091V6.79956C5 7.21422 5.16411 7.6117 5.45578 7.90455L10.2166 12.6846C10.3625 12.831 10.4444 13.0296 10.4444 13.2368V18.2181C10.4444 18.7986 11.0529 19.1762 11.5701 18.9165L13.1256 18.1356Z"/>
</g>
<defs>
<clipPath id="clip0_50_37953">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
            {
              route: "Vendor",
              name: "Vendors",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_50_37953)">
<path d="M13.1256 18.1356C13.3891 18.0034 13.5556 17.733 13.5556 17.4372V13.2368C13.5556 13.0296 13.6375 12.831 13.7834 12.6846L18.5442 7.90455C18.8359 7.6117 19 7.21422 19 6.79956V4.78091C19 4.34907 18.6523 4 18.2222 4H5.77778C5.34767 4 5 4.34907 5 4.78091V6.79956C5 7.21422 5.16411 7.6117 5.45578 7.90455L10.2166 12.6846C10.3625 12.831 10.4444 13.0296 10.4444 13.2368V18.2181C10.4444 18.7986 11.0529 19.1762 11.5701 18.9165L13.1256 18.1356Z"/>
</g>
<defs>
<clipPath id="clip0_50_37953">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
            {
              route: "BulletDescription",
              name: "Bullet Description",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_414_383)">
<path d="M18 6H16C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6H6C4.9 6 4 6.9 4 8V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8C20 6.9 19.1 6 18 6ZM10 10C10 10.55 9.55 11 9 11C8.45 11 8 10.55 8 10V8H10V10ZM12 4C13.1 4 14 4.9 14 6H10C10 4.9 10.9 4 12 4ZM16 10C16 10.55 15.55 11 15 11C14.45 11 14 10.55 14 10V8H16V10Z" />
</g>
<defs>
<clipPath id="clip0_414_383">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
          ],
          permissions: "",
          showUser: ["all"],
        },

        {
          // route: "Integrations",
          route: "AllIntegrations",
          activeLinks: [
            "AllIntegrations",
            "DetailIntegration",
            "IntegrationRedirect",
            "AccessToken",
            "Webhook",
            'webhook-log',
            'webhook-secret'
          ],
          name: "Integrations",
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M3 3H11V11H3V3ZM13 3H21V11H13V3ZM3 13H11V21H3V13ZM18 13H16V16H13V18H16V21H18V18H21V16H18V13Z" />
</svg>
`,
          permissions: "",
          showUser: ["all"],
          children: [
            {
              route: "AllIntegrations",
              name: "Integrations",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_50_37953)">
<path d="M13.1256 18.1356C13.3891 18.0034 13.5556 17.733 13.5556 17.4372V13.2368C13.5556 13.0296 13.6375 12.831 13.7834 12.6846L18.5442 7.90455C18.8359 7.6117 19 7.21422 19 6.79956V4.78091C19 4.34907 18.6523 4 18.2222 4H5.77778C5.34767 4 5 4.34907 5 4.78091V6.79956C5 7.21422 5.16411 7.6117 5.45578 7.90455L10.2166 12.6846C10.3625 12.831 10.4444 13.0296 10.4444 13.2368V18.2181C10.4444 18.7986 11.0529 19.1762 11.5701 18.9165L13.1256 18.1356Z"/>
</g>
<defs>
<clipPath id="clip0_50_37953">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

                `,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
            {
              route: "AccessToken",
              name: "API",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_50_37953)">
<path d="M13.1256 18.1356C13.3891 18.0034 13.5556 17.733 13.5556 17.4372V13.2368C13.5556 13.0296 13.6375 12.831 13.7834 12.6846L18.5442 7.90455C18.8359 7.6117 19 7.21422 19 6.79956V4.78091C19 4.34907 18.6523 4 18.2222 4H5.77778C5.34767 4 5 4.34907 5 4.78091V6.79956C5 7.21422 5.16411 7.6117 5.45578 7.90455L10.2166 12.6846C10.3625 12.831 10.4444 13.0296 10.4444 13.2368V18.2181C10.4444 18.7986 11.0529 19.1762 11.5701 18.9165L13.1256 18.1356Z"/>
</g>
<defs>
<clipPath id="clip0_50_37953">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

                `,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
            {
              route: "Webhook",
              name: "Webhooks",
              icon: `<svg width="24" height="24" viewBox="0 0 24 24"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_50_37953)">
<path d="M13.1256 18.1356C13.3891 18.0034 13.5556 17.733 13.5556 17.4372V13.2368C13.5556 13.0296 13.6375 12.831 13.7834 12.6846L18.5442 7.90455C18.8359 7.6117 19 7.21422 19 6.79956V4.78091C19 4.34907 18.6523 4 18.2222 4H5.77778C5.34767 4 5 4.34907 5 4.78091V6.79956C5 7.21422 5.16411 7.6117 5.45578 7.90455L10.2166 12.6846C10.3625 12.831 10.4444 13.0296 10.4444 13.2368V18.2181C10.4444 18.7986 11.0529 19.1762 11.5701 18.9165L13.1256 18.1356Z"/>
</g>
<defs>
<clipPath id="clip0_50_37953">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

                `,
              activeLinks: [
            'webhook-log',
            'webhook-secret'],
              permission: "",
              showUser: ["all"],
            },
          ],
        },
        {
          route: "Leads",
          activeLinks: [],
          name: "Customers",
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_460_488)">
<path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z" />
</g>
<defs>
<clipPath id="clip0_460_488">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

                `,
          permission: "",
          showUser: ["all"],
        },
        {
          route: "FlowList",
          name: "Email Flow",
          icon: `<svg width="24" height="24" viewBox="0 0 24 24"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M20 8L12 13L4 8V6L12 11L20 6M20 4H4C2.89 4 2 4.89 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V6C22 4.89 21.1 4 20 4Z"/>
</svg>


                `,
          activeLinks: [
            "Segments",
            "Email",
            "FlowList",
          
          ],
          
          permission: "",
          showUser: ["all"],
        },
        {
          route: "account",
          name: "Account",
          icon: '<i class="fa-solid fa-user">',
          permissions: "",
          activeLinks: ["UserProfile", "Invoice", "Pricing", "AccessToken"],
          showUser: [""],
          children: [
            {
              route: "UserProfile",
              name: "Account Setting",
              icon: `<i class="fa-solid fa-user">
                `,
              activeLinks: [],
              permission: "",
              showUser: ["all"],
            },
            {
              route: "Invoice",
              activeLinks: [],
              name: "Invoices & Billing",
              icon: '<i class="fa-solid fa-file-lines"></i>',
              permission: "",
              showUser: ["bc", "web"],
            },
            {
              route: "AccessToken",
              activeLinks: [],
              name: "API Keys",
              icon: `<svg width="22" height="18" viewBox="0 0 22 18"  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2 10.5V10.74C12.5 11.34 12 12.24 12 13.2V16H5.5C4 16 2.69 15.5 1.61 14.43C0.54 13.38 0 12.09 0 10.58C0 9.28 0.39 8.12 1.17 7.1C1.95 6.08 3 5.43 4.25 5.15C4.67 3.62 5.5 2.38 6.75 1.43C8 0.48 9.42 0 11 0C12.95 0 14.6 0.68 15.96 2.04C17.08 3.16 17.73 4.5 17.93 6C15.36 6.04 13.2 8.08 13.2 10.5ZM22 13.3V16.8C22 17.4 21.4 18 20.7 18H15.2C14.6 18 14 17.4 14 16.7V13.2C14 12.6 14.6 12 15.2 12V10.5C15.2 9.1 16.6 8 18 8C19.4 8 20.8 9.1 20.8 10.5V12C21.4 12 22 12.6 22 13.3ZM19.5 10.5C19.5 9.7 18.8 9.2 18 9.2C17.2 9.2 16.5 9.7 16.5 10.5V12H19.5V10.5Z"/>
</svg>`,
              permission: "",
              showUser: ["all"],
            },
          ],
        },
        {
          route: "",
          activeLinks: [],
          name: "Users",
          icon: '<i class="fa-solid fa-user-group"></i>',
          permission: "",
          showUser: [],
        },

        // {
        //   route: "Pricing",
        //   activeLinks: [],
        //   name: "Subscription",
        //   icon: `<svg width="20" height="16" viewBox="0 0 20 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        //         <path d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z" />
        //         </svg>
        //         `,
        //   permission: "",
        //   showUser: ["bc", "web"],
        // },
      ],
      unsubscribeUpgradePlansModal: null,
      upgradeModalTitle: "",
      upgradeModalsubText: "",
    };
  },
  computed: {
    ...mapGetters([
      "GetTokenFromMetaTag",
      "getAlertNotification",
      "getNavbarData",
      "getUserLimitations",
      "getUserAllAccountData",
    ]),
    getUserType() {
      return this.getNavbarData.userType;
    },
    getUserAccountData() {
      return this.getUserAllAccountData?.account;
    },
    isEmailNotVerified() {
      let accountData = this.getUserAccountData;
      if (accountData) {
        let { email_verified_at } = accountData;
        // return email_verified_at && typeof email_verified_at === "string";
        return email_verified_at && typeof email_verified_at === "string" ? false : true;
      }
      return false;
    },
    AlertObj() {
      return this.getAlertNotification;
    },
    UserAccountData() {
      return this.getNavbarData;
    },
    currentRoute() {
      return this.$route.name;
    },
    GetSideBarList() {
      return this.sideBarList;
    },
  },

 
  mounted() {},
  methods: {
    ...mapMutations(["resetUpgradePlansModal"]),
    ...mapActions(['loadAccountDataVuex']),
    hideToggleButton() {
      if (this.$refs.sidebar.classList.contains("sidebarPosition")) {
        this.$refs.sidebar.classList.remove("sidebarPosition");
        this.$refs.toggleBtn.classList.remove("toggleButtonPosition");
      }
    },
    toggleSideBar() {
      this.$refs.sidebar.classList.toggle("sidebarPosition");
      this.$refs.toggleBtn.classList.toggle("toggleButtonPosition");
    },
    openUpgradePlan() {
      this.CloseModalUpgrade();
      let webUsers = ['web','bc','wordpress']
           if( webUsers.includes(this.getUserType)){
        let url = window.location.origin + `/manage/account/pricing`;
        window.open(url, "_self");
      }

      if (this.getUserType == "wix") {
        let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`;
        window.open(url, "_blank");
      }
      if (this.getUserType == "shopify") {
        const envPath = process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
        const url = `https://${this.UserAccountData.name}${envPath}manage/subscription/plans`;
        window.open(url, "_parent");
      }
    },
    openUpgradeModal(obj) {
      this.upgradeModalTitle = obj.title;
      this.upgradeModalsubText = obj.subText;

      $("#UpgradePlanModalNewLayout").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
      // this.$refs.UpgradeUserPlanModalIndexComp.OpenModal(obj);
    },

    CloseModalUpgrade() {
      this.resetUpgradePlansModal();
      $("#UpgradePlanModalNewLayout").modal("hide");
    },
  },
  created() {

    this.loadAccountDataVuex()

    this.unsubscribeUpgradePlansModal = this.$store.subscribe((mutation) => {
      if (mutation.type === "setUpgradePlansModal") {
        // Open Modal
        this.openUpgradeModal(mutation.payload);
      }
    });
  },
  beforeDestroy() {
    // Remove the subsribtion to mutation
    this.unsubscribeUpgradePlansModal();
  },
};
</script>

<style scoped>
.asideToggleBtn {
  transform: rotateY(180deg);
}

.asideToggle {
  min-width: 0px !important;
  width: 0px !important;
}

.ulToggle {
  display: none;
}

.active {
  color: #4d1b7e;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
}

aside {
  max-width: 25%;
  width: 25%;
  min-width: 250px;
  height: 100vh;
  transition: 0.3s ease all;
  background: #ffff;
}

ul {
  list-style-type: none;
}

li {
  height: 35px;
  border-radius: 10px;

  line-height: 3;
  color: #73738d;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.aside-toggle {
  position: relative;
  text-align: center;
  left: 8px;
  background: #f4f3f5;
  color: #73738d;
  transition: 0.3s ease all;
  /* border: 1px solid grey; */
  border-radius: 50%;
  width: 25px;
  cursor: pointer;
}

.aside-toggle:hover i {
  animation: shake 0.1s;
}

.aside-toggle:hover {
  color: #4d1b7e;
}

li:hover {
  background: #f4f3f5;
}

.aside-tooltip {
  display: none;
}

.active >>> svg {
  fill: #4d1b7e !important;
}

@media (max-width: 1024px) {
  li {
    display: flex;
  }
  .sidebar {
    min-width: 100px !important;
    width: 100px !important;
    transition: 0.3s ease all;
  }
  .aside-bar-name {
    display: none;
  }

  aside {
    min-width: 100px;
    max-width: 100px;
  }

  .aside-tooltip {
    position: relative;
    left: 30px;
    z-index: 11111111;
    background: #f4f3f5;
    /* border: 2px solid black; */
    white-space: nowrap;
    padding: 0px 20px;
    /* box-shadow: 0em 3px 8px #5d5d5d; */
    box-shadow: 5px 8px 16px 0.1rem #cecccc;
    /* color: #73738d; */
    font-weight: 600;
    font-size: 14px;
    border-radius: 10px;
    line-height: 3.6;
  }

  li:hover .aside-tooltip {
    display: block;
  }
}
.toggle-btn {
  display: none;
}
@media (max-width: 557px) {
  .sidebar,
  aside {
    max-width: 250px !important;
    min-width: 250px !important;
    width: 25%;
    min-width: 250px;
  }
  .content-view {
    width: 100% !important;
    margin-left: 0px !important;
    /* Other CSS properties for devices smaller than the medium breakpoint */
  }
  .toggle-btn {
    position: fixed;
    top: 65px !important;
    left: 7px;
    padding: 6px;
    width: 30px;
    height: 30px;
    justify-content: center;
    display: flex !important;
    align-items: center;
    border-radius: 50%;
    color: #4d1b7e;
    background: white;
    box-shadow: 0px 0px 6px 1px #b3b3b3;
    cursor: pointer;
    transition: 0.3s ease all;
    z-index: 1111111111;
  }

  .toggleButtonPosition div svg {
    transform: rotate(183deg);
  }
  .toggleButtonPosition {
    left: 234px !important;
  }
  .sidebarPosition {
    left: 0px !important;
  }
  .sidebar {
    position: fixed;
    left: -300px;
  }
}
@media (min-width: 558px) and (max-width: 1024px) {
  .content-view {
    width: 90% !important;
    margin-left: 100px !important;
    /* Other CSS properties for devices smaller than the medium breakpoint */
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  10%,
  90% {
    transform: translateX(-5px);
  }

  20%,
  80% {
    transform: translateX(5px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-5px);
  }

  40%,
  60% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
